<template>
  <v-dialog v-model="show" max-width="450px">
    <div class="d-flex flex-row">
      <div
        class="flex-row-auto offcanvas-mobile w-400px w-xl-450px"
        id="kt_profile_aside"
      >
        <div class="card card-custom card-stretch">
          <div class="card-body pt-4">
            <div class="d-flex align-items-center">
              <div
                class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center"
              >
                <div
                  v-if="shop != null && shop.media != null"
                  class="symbol-label"
                  :style="{
                    backgroundImage: `url(${shop.media.fullThumbPath1})`
                  }"
                ></div>
                <i class="symbol-badge bg-success"></i>
              </div>
              <div>
                <a
                  class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
                  >{{ shop != null ? shop.name : "" }}</a
                >
              </div>
            </div>
            <!--end::User-->
            <!--begin::Contact-->
            <div class="py-9">
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="font-weight-bold mr-2">Joined on:</span>
                <a class="text-muted text-hover-primary"
                  >{{ shop != null ? shop.createDate : "" }}</a
                >
              </div>
              <div
                  class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="font-weight-bold mr-2">Owner:</span>
                <a class="text-muted text-hover-primary"
                >{{ shop != null && shop.user != null &&
                shop.user.userProfiles !=null &&
                shop.user.userProfiles[0].fullName != null ? shop.user.userProfiles[0].fullName : "" }}</a
                >
              </div>
              <div
                  class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="font-weight-bold mr-2">Owner's Email:</span>
                <a class="text-muted text-hover-primary"
                >{{ shop != null && shop.user != null &&
                shop.user.userProfiles !=null &&
                shop.user.userProfiles[0].username != null ? shop.user.userProfiles[0].username : "" }}</a
                >
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="font-weight-bold mr-2">Description:</span>
                <a class="text-muted text-hover-primary">{{
                  shop != null ? shop.description : ""
                }}</a>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="font-weight-bold mr-2">Email:</span>
                <a class="text-muted text-hover-primary">{{
                  shop != null ? shop.email : ""
                }}</a>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="font-weight-bold mr-2">Phone:</span>
                <span class="text-muted">{{
                  shop != null ? shop.phoneNumber : ""
                }}</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="font-weight-bold mr-2">Country:</span>
                <span class="text-muted">{{
                  shop != null && shop.country != null ? shop.country.name : ""
                }}</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="font-weight-bold mr-2">Followers:</span>
                <span class="text-muted">{{
                  shop != null ? shop.followers : ""
                }}</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="font-weight-bold mr-2">Number of posts:</span>
                <span class="text-muted">{{
                  shop != null ? shop.numberOfPosts : ""
                }}</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="font-weight-bold mr-2">Address:</span>
                <span class="text-muted">{{
                  shop != null ? shop.address : ""
                }}</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="font-weight-bold mr-2">Zip code:</span>
                <span class="text-muted">{{
                  shop != null ? shop.zipCode : ""
                }}</span>
              </div>
            </div>
            <!--end::Contact-->
          </div>
        </div>
      </div>

      <!--begin::Content-->

      <!--end::Content-->
    </div>
  </v-dialog>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

export default {
  props: {
    modal: {
      default: false
    },
    shop: {
      type: Object
    },
    value: Boolean
  },

  name: "custom-page",
  data() {
    return {
      tabIndex: 0
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Shop" }]);
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),

    getFullName() {
      return (
        this.currentUserPersonalInfo.name +
        " " +
        this.currentUserPersonalInfo.surname
      );
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("input", value);
        }
      }
    }
  }
};
</script>
